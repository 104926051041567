.nav-header {
    height: 5rem;
    background-color:white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.deznav {
    background-color:white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.header {
    height: 5rem;
    background-color:white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.content-body{
    background-color: #FCFCFC;
}

/* HOME */

.bordered-card{
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 8px;
}

.circle-1 {
    height: 25px;
    width: 25px;
    content: "";
    border-radius: 100%;
    transform: translate(-50%, -50%);
}

.card-bold-title{
    font-family: Montserrat;
font-style: normal;
font-weight: 800;
font-size: 32px;
line-height: 40px;
text-transform: uppercase;
color: #1A1A1A;
}

.btn { 
    border-radius: 8px;
}



/* Profile */
.jto-top-profile-item {
    position: absolute;
    top: 10%;
    left: 15%;
  }

  .profile-caption-1 figcaption {
    position: absolute;
    bottom: 65px;
    left: 0;
    right: 0;
}

.PhoneInputInput{
    border-radius: 0;
    background: #fff;
    border: 1px solid #6e6e6e;
    color: #6e6e6e;
    height: 56px;
    padding-left:15px ;
  }

  .form-control{
    border-radius: 0;
    background: #fff;
    border: 1px solid #6e6e6e;
    color: #6e6e6e;
  }


  
  .login-wrapper .login-aside-left {
    background: linear-gradient(to right, #1886b1 25%, #17556d 100%);
}

.login-wrapper .login-aside-left:after {
  background: #50c5f4;
}
.login-wrapper .login-aside-left:before {
  background: #50c5f4;
}